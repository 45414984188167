<template>
    <div class="cms-notice">
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">{{ tableTitle }}</h5>
                            </a-col>
                        </a-row>
                    </template>
                    <template>
                        <v-data-table
                            :headers="tableColumns"
                            :items="faqList"
                            class="elevation-1"
                            hide-default-footer
                            :loading="isLoading"
                            :loading-text="$t('cms.loading')"
                            :page="page"
                            :no-data-text="$t('cms.no_data_available')"
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-dialog v-model="dialogCU" persistent max-width="1000px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="primary"
                                                dark
                                                class="mb-2"
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="onAddNewItem()"
                                                small
                                            >
                                                <v-icon small> mdi-plus </v-icon>
                                                {{ $t('cms.add_new') }}
                                            </v-btn>
                                        </template>
                                        <ModalFAQ
                                            :cuItem="cuItem"
                                            :formTitle="formTitle"
                                            :closeCU="closeCU"
                                            :save="onSaveItem"
                                            :propsField="propsField"
                                            :columns="tableColumns"
                                            :dialogEdit="dialogEdit"
                                        >
                                        </ModalFAQ>
                                    </v-dialog>
                                    <v-spacer></v-spacer>
                                    <v-dialog v-model="dialogDelete" max-width="500px">
                                        <ModalDelete :closeDelete="closeDelete" :deleteItemConfirm="deleteItemConfirm">
                                        </ModalDelete>
                                    </v-dialog>
                                    <v-dialog v-model="dialogNoti" max-width="500px">
                                        <ModalNoti :close="closeNoti" :message="formMessage"></ModalNoti>
                                    </v-dialog>

                                    <div class="search">
                                        <!-- <a-select :default-value="selectedField" @change="handleChangeSelect">
                                            <a-select-option
                                                v-for="(item, index) in fieldsSearch"
                                                :key="index"
                                                :value="item.value"
                                            >
                                                {{ item.title }}
                                            </a-select-option>
                                        </a-select> -->
                                        <a-input-search
                                            v-model="search"
                                            :placeholder="$t('header.search')"
                                            @change="onSearchItem"
                                        />
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.question`]="{ item }">
                                <div class="my-2 line-clamp-2">
                                    <span>{{ item.question }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                <div class="my-2">
                                    <span>{{ convertTimeZone(+item.created_at_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.updated_at`]="{ item }">
                                <div class="my-2">
                                    <span>{{ convertTimeZone(+item.updated_at_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn color="info" class="mr-2" small @click="onUpdateItem(item)">
                                    <v-icon small> mdi-pencil </v-icon>
                                    &nbsp;{{ $t('form.edit') }}
                                </v-btn>
                                <v-btn color="error" dark small @click="onDeleteItem(item)">
                                    <v-icon small> mdi-delete </v-icon>
                                    &nbsp;{{ $t('form.delete') }}
                                </v-btn>
                            </template>
                        </v-data-table>
                        <div class="text-center py-5">
                            <f-pagination
                                v-model="page"
                                :pageSize="faqPagination.pageSize"
                                :totalItems="faqPagination.totalItems"
                                :disabled="isLoading"
                            />
                        </div>
                    </template>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ModalDelete from '../../components/CMS/Modal/Delete.vue';
import ModalFAQ from '../../components/CMS/Modal/FAQ.vue';
import ModalNoti from '../../components/CMS//Modal/Noti.vue';

import { CRUD } from '../../constants';
import { mixinFormats, mixinToasts, minxinTranslate, mixinLoadings } from '../../mixins';
import * as _ from 'lodash';

export default {
    mixins: [mixinFormats, mixinToasts, minxinTranslate, mixinLoadings],
    components: { ModalDelete, ModalFAQ, ModalNoti },
    data() {
        return {
            tableTitle: this.$t('cms.FAQ'),
            tableColumns: [
                { text: this.$t('form.question'), value: 'question', sortable: false, width: '400px' },
                { text: this.$t('form.created_at'), value: 'created_at', sortable: false, width: '200px' },
                { text: this.$t('form.updated_at'), value: 'updated_at', sortable: false, width: '200px' },
                { text: this.$t('cms.action'), value: 'actions', sortable: false, width: '210px' },
            ],
            fieldsSearch: [
                {
                    title: 'Question',
                    value: 'question',
                },
                {
                    title: 'Answer',
                    value: 'answer',
                },
            ],
            selectedField: 'question',
            search: '',
            page: 1,
            cuIndex: -1,
            cuItem: {},
            dialogCU: false,
            dialogDelete: false,
            dialogNoti: false,
            dialogEdit: false,
            formMessage: '',
        };
    },
    created() {
        this.hiddenFieldModal(this.dialogEdit);
    },
    computed: {
        ...mapState('faq', ['isLoading', 'faqList', 'faqPagination']),
        formTitle() {
            return this.cuIndex === -1 ? this.$t('action.new_question') : this.$t('action.edit_question');
        },
    },
    async mounted() {
        await this.getList({ page: this.page, field: this.selectedField, search: this.search });
    },
    watch: {
        page(val) {
            this.getList({ page: val, field: this.selectedField, search: this.search });
        },
        dialogCU(val) {
            val || this.closeCU();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        dialogNoti(val) {
            val || this.closeNoti();
        },
        dialogEdit(val) {
            this.hiddenFieldModal(val);
        },
    },
    methods: {
        ...mapActions('faq', ['getFaqList', 'postNewFAQ', 'putFAQ', 'deleteFAQ']),

        async getList(data) {
            this.page = data?.page || 1;
            this.selectedField = data?.field;
            this.search = data?.search || '';
            await this.getFaqList(data);
        },

        initialize() {
            this.getList({ page: 1, field: 'question', search: '' });
        },

        hiddenFieldModal(val) {
            if (val === true) {
                this.propsField = this.tableColumns.filter(
                    (s) => s.value !== 'password' && s.value !== 'created_at' && s.value !== 'updated_at',
                );
            } else {
                this.propsField = this.tableColumns.filter((s) => s.value !== 'created_at' && s.value !== 'updated_at');
            }
        },

        onAddNewItem() {
            this.dialogEdit = false;
        },

        onUpdateItem(item) {
            this.dialogEdit = true;
            this.cuIndex = this.faqList.indexOf(item);
            this.cuItem = Object.assign({}, item);
            this.dialogCU = true;
        },

        onDeleteItem(item) {
            this.cuIndex = this.faqList.indexOf(item);
            this.cuItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        async deleteItemConfirm() {
            this.closeDelete();
            this.showLoading();
            const res = await this.deleteFAQ(this.cuItem.id);

            if (res?.isSuccess) {
                await this.getFaqList({ page: this.page, field: this.selectedField, search: this.search });
                this.showSuccess(CRUD.DELETE);
            } else {
                this.showError(res?.message);
            }
            this.hideLoading();
        },

        closeCU() {
            this.dialogCU = false;
            this.$nextTick(() => {
                this.cuItem = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.cuItem = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },

        closeNoti() {
            this.dialogNoti = false;
        },

        async onSaveItem(data, callback) {
            let response = {};
            let getListParams = {};

            this.showLoading();

            if (this.cuIndex === -1) {
                response = await this.postNewFAQ(data);
                getListParams = { page: 1, field: 'question', search: '' };
            } else {
                response = await this.putFAQ(data);
                getListParams = { page: this.page, field: this.selectedField, search: this.search };
            }

            callback(response?.isSuccess);
            if (response?.isSuccess) {
                await this.getFaqList(getListParams);
                this.showSuccess(this.cuIndex === -1 ? CRUD.CREATE : CRUD.UPDATE);
            } else {
                this.showError(response?.message);
            }

            this.hideLoading();
        },

        onSearchItem: _.debounce(function (e) {
            this.getList({ page: 1, field: this.selectedField, search: this.search });
        }, 500),

        handleChangeSelect(value) {
            this.getList({ page: 1, field: value, search: this.search });
        },
    },
};
</script>

<style lang="scss">
.search {
    & .ant-select {
        margin-right: 10px;
        width: 175px;
    }

    & .ant-input-search {
        width: 500px;
    }
}

.faq__answer {
    & .ck.ck-content {
        & > :first-child {
            margin-bottom: 0 !important;
        }

        & img {
            width: 50px;
        }
    }
}
</style>
