<template>
    <div class="faq__form">
        <v-card lazy-validation>
            <v-card-title class="modal__title">
                <span class="text-h6">{{ formTitle }}</span>
                <button type="button" class="btn-close" @click="clickCancel">
                    <v-icon class="fa-solid fa-xmark"></v-icon>
                </button>
            </v-card-title>

            <a-form :form="form">
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <a-form-item>
                                    <label class="faq__label">{{ $t('form.question') }}</label>
                                    <a-input
                                        v-decorator="[
                                            'question',
                                            {
                                                initialValue: cuItem.question,
                                                rules: [{ required: true, message: $t('form.required') }],
                                            },
                                        ]"
                                    />
                                </a-form-item>
                            </v-col>
                            <v-col cols="12">
                                <a-form-item>
                                    <label class="faq__label">{{ $t('form.answer') }}</label>
                                    <div class="my-2">
                                        <MyEditor :data.sync="cuItem.answer" />
                                    </div>
                                    <p
                                        class="text-danger"
                                        :style="{ lineHeight: 1 }"
                                        v-if="!cuItem.answer && errorObject.answer"
                                    >
                                        {{ errorObject.answer }}
                                    </p>
                                </a-form-item>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn color="blue darken-1" text @click="clickCancel">{{ $t('cancel') }}</v-btn>
                    <v-btn type="submit" color="blue darken-1" text @click="clickSave"> {{ $t('form.save') }}</v-btn>
                </v-card-actions>
            </a-form>
        </v-card>
    </div>
</template>

<script>
import moment from 'moment';
import MyEditor from '../MyEditor';
import { mixinRules, minxinTranslate, mixinFormats, mixinToasts } from '../../../mixins';
import { TYPE_FAQ } from '../../../constants';

export default {
    mixins: [mixinRules, minxinTranslate, mixinFormats, mixinToasts],
    components: {
        MyEditor,
    },
    props: {
        cuItem: { type: Object },
        formTitle: { type: String },
        closeCU: { type: Function },
        save: { type: Function },
        propsField: { type: Array },
        dialogEdit: { type: Boolean },
    },
    data() {
        return {
            form: this.$form.createForm(this, { name: 'faq-form' }),
            errorMessages: '',
            formHasErrors: false,
            errorObject: {
                answer: '',
            },
        };
    },
    methods: {
        clickCancel() {
            this.closeCU();
            this.form.resetFields();
            this.errorObject = {
                answer: '',
            };
        },

        clickSave(e) {
            e.preventDefault();
            let isValidEditor = true;

            if (!this.cuItem.answer?.trim()) {
                this.errorObject.answer = this.$t('form.required');
                isValidEditor = false;
            } else {
                this.errorObject.answer = '';
            }

            this.form.validateFields((err, values) => {
                if (!err && isValidEditor) {
                    const body = {
                        ...this.cuItem,
                        question: values.question.trim(),
                        type: TYPE_FAQ.FUNCTION,
                    };
                    this.save(body, (isSuccess) => {
                        if (isSuccess) this.clickCancel();
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss">
.faq__form {
    & .ant-form-item {
        margin-bottom: 0;
    }
}

.faq__label {
    display: block;
    margin-bottom: 5px;
    line-height: 1;
}

.modal__title {
    display: flex;
    justify-content: space-between;
    line-height: 1;
    & .btn-close i {
        color: #aaa !important;
        transition: all 0.4s ease;
    }
    & .btn-close:hover i {
        color: #000 !important;
    }
}
</style>
